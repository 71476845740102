import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import TitleUnderline from "./titleUnderline"

import img1 from "@components/pageScore/pagePrice/images/marcas/Beex.png"
import img2 from "@components/pageScore/pagePrice/images/marcas/Genesys.png"
import img3 from "@components/pageScore/pagePrice/images/marcas/InConcert.png"
import img4 from "@components/pageScore/pagePrice/images/marcas/Avaya.png"
import img5 from "@components/pageScore/pagePrice/images/marcas/Talkdesk.png"
import img6 from "@components/pageScore/pagePrice/images/marcas/Five9.png"
import img7 from "@components/pageScore/pagePrice/images/marcas/Twilio.png"


const ComparePrice = ({ data }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    dots: true,
    speed: 500,
    arrows: true,
  }

  const features = [
    "SLA personalizado y garantizado",
    "Canales de soporte múltiples",
    "Capacitaciones continuas y personalizadas",
    "Integración directa con tu infraestructura",
    "Especialista de cuenta dedicado",
    "Resolución de problemas en tiempo real",
    "Enfoque en la relación a largo plazo",
  ]

  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 1000) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  const [fixedCabecera, setFixedCabecera] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log("scrollY:", window.scrollY);
      if (window.scrollY >= 600) {
        setFixedCabecera(false);
      } else if (window.scrollY >= 230) {
        setFixedCabecera(true);
      } else {
        setFixedCabecera(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <section className=" container-pages-price-score container-price-ticker container-price-score">

      <p className="container-page-soporte-becauseWe-title" style={{ marginBottom: '40px' }}>
        <TitleUnderline underline="Beex vs Otros" /> <TitleUnderline underline="Proveedores:" /> Soporte Premium que realmente marca la diferencia
      </p>
      <p className="container-compare-marca-description">
        Cuando eliges Beex, no solo obtienes una plataforma omnicanal de alto rendimiento. También accedes al mejor soporte del mercado, diseñado para garantizar tu éxito continuo. Aquí te mostramos por qué nuestro soporte es incomparable.
      </p>


      {windowsWidth ? (
        <div style={{ padding: '0px', marginTop: "60px" }} className="container-boxprice-ticker">
          <div
            className={`${fixedCabecera && windowsWidth ? "container-boxprice-ticker-true" : " "
              }`}
          >
            <div className="cabecera-compare-support">
              <div className="container-title-caracteristic-price-score" style={{ padding: '20px' }}>
                <p><strong> Aspecto Clave</strong></p>
              </div>
              <div className="container-price-score__containerfeatures__features container-price-ticker__containerfeatures__features" style={{ display: 'flex',  }}>
                {data.map(elemento => {
                  return (
                    <div
                      className={`container-price-score__containerfeatures__features-detail container-price-ticker__containerfeatures__features-detail`}
                      style={{ padding: '20px', background: elemento.beex ? "#F9F0FF" : "transparent", borderTop: elemento.beex ? "7px solid #B37FEB" : "7px solid transparent"
                    }}
                      key={elemento}
                    >
                      <p style={{ wordWrap: 'break-word' }}>
                        <strong>   {elemento.title}</strong>
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={`${fixedCabecera && windowsWidth ? "container-boxprice-compare-marcas-true" : " "}`} />

          <section className="container-price-ticker__containerfeatures" style={{ paddingTop: fixedCabecera && windowsWidth ? '330px' : "" }}   >
            {caracteristica(features, windowsWidth)}
            <div className="container-price-score__containerfeatures__features container-price-ticker__containerfeatures__features" style={{ display: 'flex' }}>
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-score__containerfeatures__features-detail container-price-ticker__containerfeatures__features-detail`}
                    key={elemento}
                  >
                    {Detail(elemento, windowsWidth)}
                  </div>
                )
              })}
            </div>
          </section>
        </div >
      ) : (
        <Slider {...settings}>
          {data.map(elemento => {
            return (
              <>
                {windowsWidth ? (
                  ""
                ) : (

                  <div className="container-compare-support__featureslogo">
                    <div className="container-title-caracteristic-price-score">
                      <p><strong> Aspecto Clave</strong></p>
                    </div>
                    <div
                   
                      style={{ padding: '20px', background: elemento.beex ? "#F9F0FF" : "transparent", borderTop: elemento.beex ? "7px solid #B37FEB" : "7px solid transparent"
                    }}
                      key={elemento}
                    >
                    <p>
                      {elemento.title}
                    </p>
                    </div>
                  </div>
                )}

                <section
                  className={`container-price-ticker__containerfeatures `}
                  key={elemento}
                >
                  {caracteristica(features)}
                  <div className="container-price-ticker__containerfeatures__features">
                    <div
                      className={`container-price-ticker__containerfeatures__features-detail`}
                    >
                      {Detail(elemento, windowsWidth)}
                    </div>
                  </div>
                </section>
              </>
            )
          })}
        </Slider>
      )}
    </section >
  )
}
export default ComparePrice

const Detail = elemento => {
const beex = elemento.beex
  return (
    <div>
      {elemento.info.map((item, index) => {
        return (
          <div
            className={`container-compare-support__item container-price-ticker__containerfeatures__features-detail--info ${beex ? "background-beex" : ""} ${index % 2 === 0
              ? "container-price-ticker__item-blue"
              : ""
              } `}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const caracteristica = features => {
  return (
    <div className="container-title-caracteristic-price-score">
      {features.map((item, index) => (
        <div
          className={` container-compare-support__item container-price-ticker__item ${index % 2 === 0
            ? "container-price-ticker__item-blue"
            : "blanco"
            } `}
          key={index}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
