import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="220" height="9" viewBox="0 0 220 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.9014 9C36.353 8.16452 3.04854 9.09727 0.627772 8.84548C-1.34856 8.50786 1.80979 2.5622 3.64428 1.68666C4.2873 1.37192 5.03433 1.32616 6.74589 1.46922C9.4598 1.73818 44.5515 1.20598 44.5515 1.20598C45.0148 1.38909 46.4049 0.77107 46.6602 0.994246C47.464 1.51499 104.343 0.805402 109.194 0.77679C112.134 0.759622 109.808 1.11441 113.269 0.971352C120.645 0.685228 136.72 0.959909 142.309 0.633728C146.782 0.364771 147.746 0.353335 152.039 0.570789C156.427 0.765353 179.755 0.484949 182.422 0.0672084C183.074 -0.0472411 183.576 -0.00718032 183.576 0.124437C183.576 0.153049 200.02 0.250328 200.095 0.353332C200.218 0.484949 200.719 0.547892 201.183 0.456332C202.043 0.318993 219.547 -0.178864 219.821 0.204542C220.681 1.30326 218.27 5.98424 216.237 7.12301C214.157 8.3419 202.734 6.97424 193.552 7.70672L150.801 7.78111L141.638 8.2389C140.588 7.84405 136.55 8.83976 134.111 8.13017C133.08 7.84977 125.799 7.80971 124.191 8.10728C122.792 8.33618 85.5438 7.75821 81.7236 8.55363C74.9151 7.88982 47.3032 8.83977 40.9014 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
