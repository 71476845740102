import React from "react"
import image from "./image/foto.webp"
import { TitleUnderlineOne } from "./titleUnderline"

const BecauseWe = () => {
  return (
    <section className="container-page-soporte-becauseWe">
      <section className="section-img-because">
        <img className="img-because" src={image} alt="img-because" loading="lazy" />
      </section>
      <section className="description-becausewe">
        <p className="container-page-soporte-becauseWe-title">
          <TitleUnderlineOne underline="¿Por qué" /> el soporte importa?
        </p>
        <p className="text-body-becausewe">
          La experiencia con un software omnicanal no termina cuando lo implementas; de hecho, ahí es donde comienza. El soporte constante, las actualizaciones proactivas y la asistencia personalizada que ofrecemos hacen que tu operación no solo funcione, sino que se optimice continuamente.
          <br /><br />
          Comparado con otros proveedores, que tratan el soporte como un servicio básico o un complemento opcional, en Beex creemos que el soporte es el corazón de nuestra oferta. Queremos que cada cliente sienta que cuenta con un socio estratégico, no solo con un proveedor de software.
        </p>
      </section>
    </section>
  )
}

export default BecauseWe
