/* eslint-disable react/jsx-key */
import React from "react"

//logo testimonio desktop
import Culqui from "@components/pageSoporte/components/testimonios/image/Culqui.svg"
import Kontigo from "@components/pageSoporte/components/testimonios/image/Kontigo.svg"
import Synlab from "@components/pageSoporte/components/testimonios/image/Synlab.svg"
import ProEmpresa from "@components/pageSoporte/components/testimonios/image/ProEmpresa.svg"
import Cafae from "@components/pageSoporte/components/testimonios/image/Cafae.svg"
import Royal from "@components/pageSoporte/components/testimonios/image/Royal.svg"

import imageVideo from "@components/pageSoporte/components/imgVideo.jpg"

const infoOne = [
  <p>Te ofrecemos un SLA ajustado a las necesidades de tu empresa, con tiempos de respuesta específicos que aseguran una operación sin interrupciones.</p>,
  <p>Puedes contactarnos a través de teléfono, chat en vivo, correo electrónico, WhatsApp, redes sociales y más, con acceso prioritario para clientes premium.</p>,
  <p>Ofrecemos sesiones de capacitación y actualizaciones constantes, para que tu equipo esté siempre al día y aproveche al máximo las nuevas funcionalidades.</p>,
  <p>Te ayudamos a integrar Beex con tu CRM, ERP u otras plataformas de manera rápida y eficiente, sin complicaciones técnicas.</p>,
  <p>Desde el día uno, cuentas con un especialista de cuenta que sigue tu evolución, monitorea tu uso y te ofrece recomendaciones estratégicas personalizadas.</p>,
  <p>Nuestro equipo monitorea proactivamente tu operación y actúa en tiempo real para resolver problemas antes de que afecten tu servicio.</p>,
  <p>Nos enfocamos en construir relaciones sólidas con nuestros clientes, garantizando su satisfacción y ayudándolos a crecer junto con nuestra plataforma.</p>,
]

const infoTwo= [
  <p>La mayoría ofrece SLA genéricos con tiempos de respuesta más largos, sin adaptarse a las demandas particulares de cada cliente.</p>,
  <p>Usualmente limitado a un canal o dos (generalmente correo electrónico o tickets), lo que puede generar demoras en la resolución.</p>,
  <p>La mayoría de los proveedores ofrece capacitaciones estándar o cobran tarifas adicionales por sesiones personalizadas.</p>,
  <p>Otros proveedores requieren integraciones manuales o herramientas de terceros, lo que incrementa costos y tiempos de implementación.</p>,
  <p>Generalmente no ofrecen un punto de contacto directo. Los clientes suelen pasar por distintos agentes, lo que ralentiza la solución.</p>,
  <p>La mayoría de los proveedores espera a que reportes un problema y luego sigue procesos de escalación que pueden tardar horas o días.</p>,
  <p>Otros proveedores tienden a centrarse solo en la venta inicial, con menor enfoque en el acompañamiento postventa y la mejora continua.</p>,
]


let data = {
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      testimony:
        "Se siente el respaldo y ayuda en todo momento del equipo de Beex. La agilidad en sus respuestas y procesos de soporte generan confianza y esto se demuestra en nuestros resultados obtenidos.",
      logo: Culqui,
      name: "Paul Vera",
      post: "Jefe de telemarketing de Culqi",
      link: "/casos-de-exito/culqi/"
    },
    {
      testimony:
        "Con Beex nos sentimos confiados, sumado a las plataformas robustas que nos brindan para mejorar nuestra operación, comparten un gran respaldo en soporte ante cualquier necesidad, de la manera más amable y rápida.",
      logo: Kontigo,
      name: "Analucía Siguas Velásquezy",
      post: "Gerente de Marketing y Producto en GMoney",
      link: "/casos-de-exito/kontigo/"
    },

    {
      testimony:
        "El soporte y acompañamiento de Beex es A1. Puedo confirmar que es un servicio de excelencia, contando con un equipo de servicio al cliente rápido, preciso y empático.",
      logo: Synlab,
      name: "Yesenia Sánchez Rodríguez",
      post: "Jefa de Servicio al Cliente en SYNLAB",
      link: "/casos-de-exito/synlab/"
    },
    {
      testimony:
        "El acompañamiento de Beex es bastante bueno. Responden de inmediato y dan solución a nuestros casos el mismo día o máximo al día siguiente. En general, los recomiendo porque esta experiencia nos ha servido para recuperar clientes con los que no teníamos contacto y hemos podido fidelizarlos nuevamente.",
      logo: ProEmpresa,
      name: "Nancy Rodriguez Siancas",
      post: "Marketing Leader en Proempresa",
      link: "/casos-de-exito/proempresa/"
    },
    {
      testimony:
        "El soporte de Beex es genial. Siempre responden de inmediato a las consultas sobre el manejo de sus soluciones. Realmente todo va muy bien, teniendo una estabilidad ideal para nuestros procesos de atención omnicanal.",
      logo: Cafae,
      name: "Johans Herencia",
      post: "Responsable de Marketing Digital en CAFAE-SE",
      link: "/casos-de-exito/cafae-se/"
    },
    {
      testimony:
        "El servicio de soporte de Beex es muy bueno. Súper confiable y que ha ayudado mucho a la marca. Sentimos que es una atención personalizada y podemos contar con ellos de lunes a domingo, lo que es importante para nosotros.",
      logo: Royal,
      name: "Liliana López",
      post: "Jefa de Marketing en Royal Enfield",
      link: "/casos-de-exito/royal-enfield/"
    },
  ],
  contacts: {
    title: "¿Preparad@ para una experiencia inolvidable?",
    textbody:
      "Ponte en contacto con nosotros en cualquier momento, para nuestros clientes favoritos como tú, nunca habrá descanso. ",
    btn: "Quiero hablar con un experto",
    href: "/hablemos/",
  },
  bannerVideo: {
    title: "¿Cuál es el proceso de soporte y soluciones de Beex?",
    description: "<p> Descubre cómo Beex maneja cada etapa del soporte y las soluciones que ofrece. Conoce las herramientas y técnicas que utilizan para garantizar la satisfacción de los clientes. <br/><br/> Observa cómo se abordan los desafíos y qué pasos se siguen para resolver cualquier inconveniente. Aprende sobre el enfoque y los métodos que Beex emplea para mejorar la experiencia de cada usuario.</p>",
    image: imageVideo
  },
  compare: [
    {
      title: <svg width="113" height="32" viewBox="0 0 113 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.6025 12.3246L28.5737 3.61445C27.2869 1.38479 24.8876 0 22.3126 0H12.255C9.67989 0 7.28063 1.38479 5.99387 3.61445L0.965071 12.3246C-0.32169 14.5543 -0.32169 17.3254 0.965071 19.555L8.08038 31.8796H22.311C24.8861 31.8796 27.2853 30.4949 28.5721 28.2652L33.6009 19.555C34.8877 17.3254 34.8877 14.5543 33.6009 12.3246H33.6025ZM12.255 4.25394H22.3126C23.3722 4.25394 24.3602 4.82497 24.8908 5.74297L29.9195 14.4531C30.4501 15.3711 30.4501 16.5116 29.9195 17.4296L28.9642 19.0836C28.8444 19.096 28.7246 19.1038 28.6032 19.1038H15.4524L8.87702 7.7159C8.8179 7.61477 8.76655 7.50896 8.71832 7.40316L9.67678 5.74297C10.2074 4.82497 11.1954 4.25394 12.255 4.25394ZM22.3126 27.6257H10.5372L4.64954 17.4281C4.11896 16.5101 4.11896 15.3696 4.64954 14.4516L6.25215 11.6742L12.9972 23.3562H26.4965L24.8908 26.1382C24.3602 27.0562 23.3722 27.6273 22.3126 27.6273V27.6257Z" fill="#FFB800"/>
      <path d="M58.1998 17.5567C57.8373 17.3467 57.4218 17.1864 56.9613 17.0697C57.1495 16.9935 57.3316 16.911 57.5012 16.8192C58.4316 16.312 59.1116 15.6476 59.541 14.8276C59.9689 14.0076 60.1836 13.1269 60.1836 12.1856C60.1836 10.0275 59.4165 8.42957 57.8824 7.39175C56.3482 6.3555 54.2213 5.83582 51.5015 5.83582H43.3281V28.9446H52.2639C54.9728 28.9446 57.0515 28.3705 58.5016 27.2222C59.9518 26.0739 60.6768 24.3733 60.6768 22.1187C60.6768 21.2085 60.4808 20.3465 60.0903 19.5312C59.6982 18.7159 59.0696 18.0577 58.2014 17.5552L58.1998 17.5567ZM51.5015 9.86725C52.782 9.86725 53.7187 10.0898 54.3115 10.5332C54.9043 10.9782 55.1999 11.7079 55.1999 12.724C55.1999 13.6124 54.8981 14.2923 54.2959 14.7638C53.6922 15.2352 52.7618 15.4702 51.503 15.4702H48.3289V9.86725H51.503H51.5015ZM54.8825 24.1119C54.3317 24.6565 53.4588 24.9287 52.2639 24.9287H48.3274V18.9446H52.4693C53.241 18.9446 53.8634 19.0722 54.3348 19.3258C54.8047 19.5794 55.1517 19.9389 55.3742 20.4056C55.5967 20.8709 55.7072 21.4263 55.7072 22.072C55.7072 22.8874 55.4318 23.5673 54.8825 24.1119Z" fill="black"/>
      <path d="M75.2144 12.5012C74.0817 11.8026 72.6844 11.4541 71.0242 11.4541C69.3641 11.4541 67.9139 11.8353 66.7065 12.5962C65.5007 13.3586 64.5718 14.4088 63.9214 15.7469C63.271 17.085 62.9458 18.6114 62.9458 20.3261V20.9453C62.9458 22.4686 63.2865 23.8612 63.9696 25.1199C64.6527 26.3787 65.636 27.3838 66.9212 28.1353C68.2064 28.8869 69.7546 29.2618 71.5641 29.2618C73.2461 29.2618 74.6511 28.9398 75.7776 28.294C76.9041 27.6483 77.7381 26.9404 78.278 26.1671L75.9612 23.5157C75.4633 24.1615 74.8612 24.6345 74.1517 24.9363C73.4422 25.2382 72.6813 25.3891 71.866 25.3891C70.9978 25.3891 70.2572 25.2008 69.6441 24.8258C69.0311 24.4509 68.5596 23.9296 68.2313 23.2621C68.0322 22.8576 67.8937 22.4126 67.8159 21.9287H78.6592V19.8966C78.6592 18.1929 78.3683 16.7085 77.7863 15.4451C77.2044 14.1801 76.3471 13.1999 75.2144 12.5012ZM73.9447 18.7079H67.8595C67.9108 18.3796 67.9793 18.0684 68.0648 17.7712C68.2811 17.0197 68.6281 16.4222 69.1042 15.9772C69.5803 15.5322 70.2105 15.3113 70.9931 15.3113C71.6808 15.3113 72.2394 15.4389 72.6673 15.6925C73.0952 15.9461 73.4157 16.3009 73.6273 16.7552C73.8389 17.2111 73.9447 17.7339 73.9447 18.3267V18.7079Z" fill="black"/>
      <path d="M92.669 12.5012C91.5362 11.8026 90.139 11.4541 88.4788 11.4541C86.8186 11.4541 85.3685 11.8353 84.1611 12.5962C82.9552 13.3586 82.0263 14.4088 81.376 15.7469C80.7256 17.085 80.4004 18.6114 80.4004 20.3261V20.9453C80.4004 22.4686 80.7411 23.8612 81.4242 25.1199C82.1072 26.3787 83.0906 27.3838 84.3758 28.1353C85.661 28.8869 87.2092 29.2618 89.0187 29.2618C90.7007 29.2618 92.1057 28.9398 93.2322 28.294C94.3587 27.6483 95.1927 26.9404 95.7326 26.1671L93.4158 23.5157C92.9179 24.1615 92.3158 24.6345 91.6063 24.9363C90.8968 25.2382 90.1359 25.3891 89.3206 25.3891C88.4524 25.3891 87.7117 25.2008 87.0987 24.8258C86.4857 24.4509 86.0142 23.9296 85.6859 23.2621C85.4868 22.8576 85.3483 22.4126 85.2705 21.9287H96.1138V19.8966C96.1138 18.1929 95.8229 16.7085 95.2409 15.4451C94.659 14.1801 93.8017 13.1999 92.669 12.5012ZM91.3993 18.7079H85.314C85.3654 18.3796 85.4338 18.0684 85.5194 17.7712C85.7357 17.0197 86.0827 16.4222 86.5588 15.9772C87.0349 15.5322 87.6651 15.3113 88.4477 15.3113C89.1354 15.3113 89.694 15.4389 90.1219 15.6925C90.5498 15.9461 90.8703 16.3009 91.0819 16.7552C91.2935 17.2111 91.3993 17.7339 91.3993 18.3267V18.7079Z" fill="black"/>
      <path d="M108.001 20.1673L112.795 11.7715H107.699L104.937 16.7396L102.239 11.7715H97.1126L101.906 20.1673L96.8901 28.9444H102.033L104.937 23.676L107.891 28.9444H113L108.001 20.1673Z" fill="black"/>
      </svg>,
      info: infoOne,
      beex: true
    },
    {
      title: "Otros Proveedores",
      info: infoTwo,
    },
  ],
}

export default data


