import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="371" height="10" viewBox="0 0 371 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M68.853 9.64229C61.1994 8.64989 5.1308 9.52953 1.0566 9.2242C-2.26909 8.82233 3.07014 1.97124 6.16164 0.970521C7.24529 0.610685 8.50303 0.56225 11.3837 0.737731C15.9513 1.06464 75.027 0.658443 75.027 0.658443C75.8063 0.872784 78.1487 0.166959 78.5777 0.426346C79.9288 1.03282 175.681 0.551259 183.848 0.547054C188.798 0.544711 184.881 0.940816 190.708 0.796103C203.125 0.509376 230.186 0.922376 239.595 0.578735C247.125 0.294576 248.749 0.287099 255.975 0.563891C263.361 0.814798 302.633 0.629571 307.124 0.162757C308.222 0.0343977 309.066 0.0836669 309.065 0.235743C309.065 0.268803 336.747 0.479018 336.874 0.598484C337.081 0.751291 337.924 0.827 338.704 0.723964C340.154 0.570394 369.621 0.0992635 370.081 0.543899C371.525 1.81852 367.448 7.21279 364.022 8.51649C360.515 9.91247 341.29 8.26427 325.831 9.05599L253.863 8.88765L238.436 9.3621C236.671 8.89963 229.87 10.0261 225.765 9.19169C224.031 8.86158 211.774 8.77197 209.067 9.10624C206.71 9.36239 144.009 8.47302 137.575 9.36936C126.116 8.56187 79.6305 9.49524 68.853 9.64229Z" fill="#FFA700"/>
</svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="194" height="8" viewBox="0 0 194 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.0676 8C32.0568 7.25735 2.68826 8.08646 0.553581 7.86265C-1.18918 7.56254 1.5959 2.27751 3.21359 1.49925C3.78061 1.21949 4.43936 1.17881 5.94865 1.30597C8.34182 1.54505 39.2863 1.07198 39.2863 1.07198C39.6949 1.23475 40.9207 0.685396 41.1458 0.883775C41.8546 1.34666 92.0112 0.715913 96.2889 0.69048C98.8822 0.67522 96.8309 0.99059 99.8828 0.863424C106.387 0.609092 120.563 0.853252 125.491 0.563314C129.435 0.324241 130.285 0.314075 134.071 0.507368C137.94 0.680314 158.511 0.431066 160.863 0.0597408C161.438 -0.0419921 161.88 -0.00638251 161.88 0.11061C161.88 0.136044 176.381 0.222513 176.448 0.314073C176.556 0.431066 176.998 0.487015 177.407 0.405629C178.166 0.283549 193.6 -0.15899 193.842 0.181815C194.601 1.15845 192.475 5.31932 190.682 6.33157C188.847 7.41502 178.774 6.19932 170.677 6.85041L132.979 6.91654L124.899 7.32347C123.973 6.97249 120.412 7.85756 118.261 7.22682C117.352 6.97757 110.931 6.94196 109.514 7.20647C108.28 7.40994 75.4341 6.89618 72.0653 7.60323C66.0615 7.01318 41.7129 7.85757 36.0676 8Z" fill="#FFA700"/>
</svg>
  </span>
)