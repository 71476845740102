import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="318" height="11" viewBox="0 0 318 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.979 9.59711C51.627 8.77612 3.83219 11.1114 1.09374 10.9958C-1.80659 10.7283 1.70079 4.28803 4.20991 3.26636C5.74776 2.64605 5.63984 3.09608 18.9004 2.64605C18.9004 2.64605 38.8925 1.98926 63.4711 1.223C63.4711 1.223 102.956 0.231727 157.32 0.000633187C161.597 -0.0176111 158.211 0.365518 163.242 0.231727C173.967 -0.0419367 197.305 0.474984 205.425 0.256053C211.941 0.0796917 213.344 0.0675293 219.563 0.402007C223.839 0.633101 259.722 1.20475 263.702 0.858114C264.673 0.772974 265.375 0.827706 265.361 0.967579C265.644 0.979742 289.157 1.8129 289.265 1.94061C289.427 2.08656 290.142 2.17778 290.83 2.1048C292.098 1.96493 317.527 2.39671 317.864 2.82241C318.835 4.0387 314.397 8.84302 311.281 9.95592C308.057 11.1053 292.422 9.08627 278.608 9.43291C214.18 7.6389 214.68 8.095 203.915 8.33218C202.431 7.88823 196.576 8.84302 193.096 8.08284C191.639 7.76052 181.171 7.6389 178.823 7.91256C178.324 7.97337 174.588 7.96121 169.192 7.91864C166.494 7.9004 163.377 7.86999 160.045 7.84566C155.594 7.80917 121.909 7.64498 117.687 8.35042C106.329 7.64498 69.7844 9.17141 58.979 9.59711Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="202" height="11" viewBox="0 0 202 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.4755 9.22142C32.659 8.4547 3.04629 10.3998 0.933836 10.2471C-0.940185 9.98434 0.390429 4.11281 1.84904 3.19489C2.36619 2.86863 3.04036 2.78344 4.65125 2.87712C6.06408 2.95841 15.8016 2.52142 39.3587 1.55532C39.82 1.72568 40.9863 1.09072 41.2623 1.30461C41.4729 1.42875 44.8942 1.3884 49.9802 1.29129C55.687 1.18126 71.2228 0.796729 78.8848 0.648482C90.1929 0.422182 85.9395 0.457409 98.8851 0.274697C101.593 0.235536 99.4775 0.602203 102.662 0.4543C109.453 0.144345 124.298 0.447949 129.438 0.166912C133.56 -0.0589701 134.458 -0.070196 138.419 0.171965C141.588 0.364578 163.95 0.424127 166.475 0.0823155C167.084 -0.0200305 167.546 0.0330627 167.532 0.161403C167.796 0.174197 182.679 0.606471 182.745 0.712965C182.85 0.842038 183.298 0.917385 183.735 0.836316C184.528 0.696024 200.709 0.678416 200.931 1.05948C201.558 2.15776 198.796 6.64128 196.848 7.70695C194.807 8.8223 184.778 7.21383 176.163 7.73591C176.163 7.73591 173.733 7.69302 170.088 7.62032C134.494 6.95442 135.708 7.269 128.916 7.58454C127.955 7.18775 124.316 8.12575 122.062 7.4474C121.126 7.16803 114.508 7.12728 113.027 7.39734C112.71 7.45681 110.359 7.46467 106.938 7.46035C103.53 7.45612 99.0524 7.43413 94.5216 7.46206C92.2496 7.47599 89.9645 7.48425 87.7981 7.51C85.6185 7.53008 83.5445 7.57873 81.7081 7.62884C78.0223 7.72898 75.274 7.86282 74.4274 8.06979C67.7078 7.48119 43.8971 8.8701 37.4755 9.22142Z" fill="#FFBA00" />
    </svg>

  </span>
)